import {
  Formatter,
  staticProjectorAccessoriesData,
  staticProjectorRentalData,
  Validators,
} from "@with-nx/hooks-n-helpers";
import moment from "moment";
import { GetStartedDetails } from "./contact-form";

export const isEmpty = (value?: string) =>
  !value?.length || value === undefined || value === null;

export const hasTwoWords = (value?: string) => value?.includes(" ");

export const hasSelectedOption = (value?: string) =>
  !isEmpty(value) && String(value).length >= 1;

export const validators: {
  [key: string]: any;
} = {
  name: (value?: string) =>
    isEmpty(value) || !hasTwoWords(value) ? "Please enter your full name" : "",
  email: (value?: string) =>
    !Validators.isValidEmailAddress(value || "")
      ? "Please enter a valid email address"
      : "",
  phone_number: (value?: string) =>
    isEmpty(value) ? "Please enter a valid phone number" : "",
  organization: (value?: string) =>
    isEmpty(value) ? "Please enter a valid organization name" : "",
  organization_type: (value?: string) => !!hasSelectedOption(value),
  region: (value?: string) => (isEmpty(value) ? "Required" : ""),
  state: (value?: string, region?: string) => {
    return region === "🇺🇸 United States" || region === "🇨🇦 Canada"
      ? !isEmpty(value)
      : true;
  },
  show: (value?: string) =>
    isEmpty(value) ? "Please enter your show name" : "",
  opening_night: (value?: string, closingNight?: string) => {
    if (isEmpty(value)) {
      return "Please select an Opening Night date";
    }

    if (!moment(value, "YYYY-MM-DD", true).isValid()) {
      return "Opening night date is not valid.";
    }

    if (moment(value).isBefore(moment())) {
      return "Opening Night date must be in the future";
    }

    if (
      !isEmpty(closingNight) &&
      moment(closingNight).isBefore(moment(value))
    ) {
      return "Closing night date must be greater or equal than Opening night date.";
    }

    return undefined;
  },
  closing_night: (value?: string, openingNight?: string) => {
    if (isEmpty(openingNight)) {
      return "Please select an Opening Night date";
    }

    if (isEmpty(value)) {
      return "Please select a Closing Night date";
    }

    if (!moment(value, "YYYY-MM-DD", true).isValid()) {
      return "Closing night date is not valid.";
    }

    if (moment(value).isBefore(moment(openingNight))) {
      return "Closing night date must be greater or equal than Opening night date.";
    }

    if (moment(value).isBefore(moment())) {
      return "Closing Night date must be in the future";
    }

    return undefined;
  },
  customer_type: (value?: string) => !isEmpty(value),
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<F>): void => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

export const countryRequiresState = (value: string) =>
  ["🇺🇸 United States", "🇨🇦 Canada"].includes(value);

export const getComplementaryValidationValue = (
  field: string,
  values: GetStartedDetails
) => {
  if (field === "state") return values?.region;

  if (field === "opening_night") return values?.closing_night;

  if (field === "closing_night") return values?.opening_night;

  return undefined;
};

export const getDefaultMessageProducts = (
  action?: string,
  referrer?: string
) => {
  if (action === "get-started" && typeof referrer === "string") {
    if (referrer.includes("/scenic-projections")) {
      return {
        message:
          "I just came from the Scenic Projections Landing page and I have some questions...",
        products: ["Digital Scenery"],
      };
    }

    if (referrer.includes("/choreography-guides")) {
      return {
        message:
          "I just came from the Choreography Guides Landing page and I have some questions...",
        products: ["Choreography Guides"],
      };
    }

    if (referrer.includes("/stage-gear")) {
      return {
        message:
          "I just came from the StageGear Landing page and I have some questions...",
        products: ["Projector Hardware", "Wireless Microphone Systems"],
      };
    }

    if (referrer.includes("/bodymics")) {
      return {
        message:
          "I just came from the BodyMics Landing page and I have some questions...",
        products: ["Projector Hardware", "Wireless Microphone Systems"],
      };
    }

    if (referrer.includes("/shows/")) {
      const show: string | undefined = referrer.split("/")[3];

      if (show) {
        const friendly = show
          ?.split("-")
          ?.map((i) => {
            if (i === "kids") {
              return "KIDS";
            } else if (i === "jr") {
              return "JR.";
            }
            return Formatter.friendly(i);
          })
          .join(" ");

        const _ps: string[] = [];

        if (referrer.includes("scenic-projections")) {
          _ps.push("Digital Scenery");
        }

        if (referrer.includes("choreography-guides")) {
          _ps.push("Choreography Guides");
        }

        return {
          message: `I just came from the ${friendly} landing page and I have some questions...`,
          show: friendly,
          products: [..._ps],
        };
      }
    }

    if (referrer.includes("/projectors/")) {
      const item: string | undefined = referrer.split("/").at(-1);
      if (item) {
        const hardware =
          staticProjectorRentalData.find((i) => i.slug === item) ||
          staticProjectorAccessoriesData.find((i) => i.slug === item);

        const _ps: string[] = [];
        _ps.push("Projector Hardware");

        return {
          message: `I’m interested in a "StageProjector" ${hardware?.name}...`,
          products: [..._ps],
        };
      }
    }
  }

  return {};
};

export const buildMeta = (
  values: GetStartedDetails,
  search?: (id?: string) => string
) => {
  const meta: {
    [key: string]: string;
  } = {};

  if (values.show) {
    meta["Show"] = values?.show;
  }
  if (values?.opening_night) {
    meta["Opening Night"] = values?.opening_night;
  }

  if (values?.closing_night) {
    meta["Closing Night"] = values?.closing_night;
  }

  if (values?.organization_type) {
    meta["Organization Type"] = search?.(values?.organization_type) || "";
  }

  if (values?.customer_type) {
    meta["Customer Type"] = values?.customer_type;
  }

  if (values?.products) {
    meta["Products"] = values?.products.join(", ");
  }

  if (values?.region) {
    meta["Region"] = values?.region;
  }

  if (values?.state) {
    meta["State"] = values?.state;
  }

  meta["Message"] = values?.message || "N/A";

  return meta;
};

export const getRegion = (values: GetStartedDetails) => {
  switch (values?.region) {
    case "🇺🇸 United States":
      return "US";
    case "🇨🇦 Canada":
      return "CA";
    case "🇦🇺 Australia":
      return "AU/NZ";
    case "🇳🇿 New Zealand":
      return "AU/NZ";
    case "🇬🇧 United Kingdom":
      return "GB/Europe";
    case "🇪🇺 Europe":
      return "GB/Europe";
    case "🌍 Global":
      return "OTHER";
    default:
      return "US";
  }
};

export const getServerRegionByCountry = (country: string) => {
  if (["🇺🇸 United States", "🇨🇦 Canada"].includes(country)) {
    return "USA/CANADA";
  }

  if (["🇦🇺 Australia", "🇳🇿 New Zealand"].includes(country)) {
    return "AUSTRALASIA";
  }

  if (["🇬🇧 United Kingdom", "🇪🇺 Europe"].includes(country)) {
    return "UK/EUROPE";
  }

  return "";
};

export const getSubject = (mode: string) => {
  switch (mode) {
    case "get-started":
      return "[Getting Started Form Submission]";

    case "request-sales-quote":
      return "[Request Sales Quota Form Submission]";

    case "order-with-representative":
      return "[Order with Representative Form Submission]";

    case "learn-more":
      return "[Learn More Form Submission]";

    default:
      return "[Contact Form Submission]";
  }
};
