import { useMobile, useStaff } from "@with-nx/hooks-n-helpers";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import {
  AutoScroller,
  GridStepScroller,
  MobileViewSwitcher,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { FC, ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import DesignedButton from "../designed-button/designed-button";
import DesignedCard from "../designed-card/designed-card";
import { ReusableSection } from "../reusable-section/reusable-section";
import Rule from "../rule/rule";

export interface ReusableCustomerServiceContainerProps {
  top?: number;
  buttonLabel?: string;
  title?: ReactNode;
  actions?: ReactNode;
  content?: ReactNode;
  showMobile?: boolean;
  buttonTheme?: string;
  lg?: number;
  xl?: number;
}

export const Element: FC<ReusableCustomerServiceContainerProps> = ({
  top,
  buttonLabel,
  title,
  content,
  actions,
  buttonTheme,
  xl,
  lg,
}) => {
  const staff = useStaff();
  const team = staff.filter((i) => i.customerServiceTeam);
  const items = team.map((item, i) => (
    <Box parse="w:256 mr:18 ml:18" key={i} style={{ minWidth: 256 }}>
      <DesignedCard
        newImage
        image={item?.attachments?.[0]?.blob?.signedUrl}
        gradient={{ direction: "bottom" }}
        outside={{
          title: `${item.firstName} ${item.lastName}`,
          subtitle: item.title || "Customer Service",
        }}
      />
    </Box>
  ));

  return (
    <MobileViewSwitcher
      render={(mobile) => (
        <ReusableSection top={top} bottom={96}>
          <div className={mobile ? "container" : "container container-left"}>
            <Row
              gutter={[36, 18]}
              style={mobile ? undefined : { marginRight: 0, paddingRight: 0 }}
              align="middle"
            >
              <Col xs={24} sm={24} md={12} lg={lg || 8} xl={xl || 6}>
                <Box parse={mobile ? "ta:center mb:30" : ""}>
                  <Rule
                    parse={`!${mobile ? "_h2 ta:left" : "hl"} d:block mb:16`}
                    tag="p"
                  >
                    {title}
                  </Rule>

                  {content || (
                    <Rule
                      parse={`!${
                        mobile ? "_bxl" : "ls"
                      } d:block c:#E1E1E1 mb:16`}
                    >
                      Our Friendly team is here to {mobile && <br />} support
                      you
                    </Rule>
                  )}

                  <Box parse="d:flex">
                    <DesignedButton
                      label={buttonLabel}
                      theme={buttonTheme}
                      href="/contact/calendar"
                    />

                    {actions}
                  </Box>
                </Box>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={12}
                lg={lg ? 24 - lg : 16}
                xl={xl ? 24 - xl : 18}
              >
                <Box parse="p:relative">
                  {mobile ? (
                    <GridStepScroller xs={24} items={items} bypass={100} />
                  ) : (
                    <AutoScroller>{items}</AutoScroller>
                  )}
                </Box>
              </Col>
            </Row>
          </div>
        </ReusableSection>
      )}
    />
  );
};

export const ReusableCustomerServiceContainer: FC<
  ReusableCustomerServiceContainerProps
> = ({
  top = 96,
  buttonLabel = "Schedule Consultation",
  buttonTheme = "tetriary",
  title = "Our customer service team is here to help!",
  showMobile = false,
  content,
  actions,
  lg,
  xl,
}) => {
  const mobile = useMobile();

  if (mobile && !showMobile) {
    return null;
  }

  return (
    <VisibleContainer>
      <Element
        top={top}
        buttonLabel={buttonLabel}
        buttonTheme={buttonTheme}
        title={title}
        content={content}
        actions={actions}
        lg={lg}
        xl={xl}
      />
    </VisibleContainer>
  );
};

export default ReusableCustomerServiceContainer;
